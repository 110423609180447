import React from 'react';
import useScript from 'react-script-hook';

const HubspotTrackingProvider: React.FC = () => {
	useScript({
		src: `//js.hs-scripts.com/${import.meta.env.VITE_HS_TRACKING_ID}.js`,
		checkForExisting: true,
	});

	return null;
};

export default HubspotTrackingProvider;
