import React from 'react';
import useScript from 'react-script-hook';

declare global {
	interface Window {
		_hjSettings?: {
			hjid: string;
			hjsv: string;
		};
	}
}

const HotjarTrackingProvider: React.FC = () => {
	const hotjarId = import.meta.env.VITE_HOTJAR_ID;
	const hotjarSv = import.meta.env.VITE_HOTJAR_SV;

	// Set up Hotjar settings, before the script has loaded
	window._hjSettings = { hjid: hotjarId, hjsv: hotjarSv };

	useScript({
		src: `https://static.hotjar.com/c/hotjar-${hotjarId}.js?sv=${hotjarSv}`,
		checkForExisting: true,
	});

	return null;
};

export default HotjarTrackingProvider;
